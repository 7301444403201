<template>
  <b-container fluid>
    {{form}}
    {{validation}}
    <b-container>
      <b-form>
        <div v-if="step === 1">
          <h1>Rejestracja w programie</h1>
          <div class="first-step-text">
            Dokonaj rejestracji w programie - tylko zarejestrowany Uczestnik może odbierać nagrody!
          </div>
          <b-form-group id="card-card_nr" label="Nr karty" label-for="card_nr">
            <b-input-group :prepend=card_settings.prefix.toString()>
              <b-form-input v-model="form.step1.card_nr"
                            max-length="13"
                            @input="$v.form.step1.card_nr.$touch()"
                            @change="resetApiValidation('card_nr')"
                            :state="(!$v.form.step1.card_nr.$error && validation.card_nr == null) && null"
                            aria-describedby="card-feedback"></b-form-input>
              <b-form-invalid-feedback id="card-feedback">
                <div class="error" v-if="!$v.form.step1.card_nr.required">Pole wymagane</div>
                <div class="error" v-if="!$v.form.step1.card_nr.minLength">Niepoprawny numer karty
                </div>
                <div class="error" v-if="!$v.form.step1.card_nr.numeric">Pole musi być numeryczne
                </div>
                {{ validation.card_nr }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-form-group id="cell_phone-card_nr" label="Nr tel" label-for="cell_phone">
            <b-input-group prepend="+48">
              <b-form-input v-model="form.step1.cell_phone"
                            @input="$v.form.step1.cell_phone.$touch()"
                            @change="resetApiValidation('cell_phone')"
                            maxlength="9"
                            :state="(!$v.form.step1.cell_phone.$error && validation.cell_phone == null) && null"
                            aria-describedby="cell_phone-feedback"></b-form-input>
              <b-form-invalid-feedback id="cell_phone-feedback">
                <div class="error" v-if="!$v.form.step1.cell_phone.required">Pole wymagane</div>
                <div class="error" v-if="!$v.form.step1.cell_phone.minLength">Niepoprawny numer
                  telefonu
                </div>
                <div class="error" v-if="!$v.form.step1.cell_phone.numeric">Pole musi być numeryczne
                </div>
                {{ validation.cell_phone }}
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <b-button variant="primary" @click="stepOne()" :disabled="loading" class="float-right">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Przejdź dalej
          </b-button>

        </div>
        <div v-if="step === 2">
          <h1 class="mb-4">Potwierdź dane</h1>
          <b-form-group class="col-md-12" id="sms" label=" " label-for="sms">
            Podaj kod z SMS, który wysłaliśmy na numer <b>{{ getSmsSended }}</b>
            <b-form-input v-model="form.step2.sms_code" class="mt-2"
                          @input="$v.form.step2.sms_code.$touch()"
                          @change="resetApiValidation('sms_code') && checkToken()"
                          :state="validation.sms_code===true || ((!$v.form.step2.sms_code.$error && validation.sms_code == null) && null)"
                          aria-describedby="sms_code-feedback"></b-form-input>
            <b-form-invalid-feedback id="sms_code-feedback">
              <div class="error" v-if="!$v.form.step2.sms_code.required">Pole wymagane</div>
              <div class="error" v-if="!$v.form.step2.sms_code.numeric">Kod składa się z cyfr</div>
              {{ validation.sms_code }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-md-12" id="birth-date" label="Data urodzenia"
                        label-for="birth_date">
            <b-form-input :type="`date`" v-model="form.step2.birth_date"
                          @input="$v.form.step2.birth_date.$touch()"
                          @change="resetApiValidation('birth_date')"
                          :state="(!$v.form.step2.birth_date.$error && validation.birth_date == null) && null"
                          aria-describedby="birth-date-feedback"></b-form-input>
            <b-form-invalid-feedback id="birth-date-feedback">
              <div class="error" v-if="!$v.form.step2.birth_date.required">Pole wymagane</div>
              {{ validation.birth_date }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button variant="danger" :disabled="loading" @click="$refs.confirmReset.showModal()">
            Powrót
          </b-button>
          <b-button variant="primary" @click="stepTwo()" :disabled="loading" class="float-right">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Przejdź dalej
          </b-button>
        </div>
        <div v-if="step === 3">
          <h1 class="mb-4">Uzupełnij formularz rejestracyjny</h1>

          <b-row>
            <b-form-group class="col-md-4" id="sex" label="Płeć *" label-for="sex">
              <b-form-select v-model="form.step3.sex"
                             @input="$v.form.step3.sex.$touch()"
                             @change="resetApiValidation('sex')"
                             :state="(!$v.form.step3.sex.$error && validation.sex == null) && null"
                             :options="sex"
                             aria-describedby="sex-feedback"></b-form-select>
              <b-form-invalid-feedback id="sex-feedback">
                <div class="error" v-if="!$v.form.step3.sex.beetween">Pole wymagane</div>
                {{ validation.sex }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="col-md-4" id="first-name" label="Imię *" label-for="first_name">
              <b-form-input v-model="form.step3.first_name"
                            @input="$v.form.step3.first_name.$touch()"
                            @change="resetApiValidation('first_name')"
                            :state="(!$v.form.step3.first_name.$error && validation.first_name == null) && null"
                            aria-describedby="first_name-feedback"></b-form-input>
              <b-form-invalid-feedback id="first_name-feedback">
                <div class="error" v-if="!$v.form.step3.first_name.required">Pole wymagane</div>
                {{ validation.first_name }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="col-md-4" id="last-name" label="Nazwisko *" label-for="lastname">
              <b-form-input v-model="form.step3.last_name"
                            @input="$v.form.step3.last_name.$touch()"
                            @change="resetApiValidation('last_name')"
                            :state="(!$v.form.step3.last_name.$error && validation.last_name == null) && null"
                            aria-describedby="first_name-feedback"></b-form-input>
              <b-form-invalid-feedback id="first_name-feedback">
                <div class="error" v-if="!$v.form.step3.last_name.required">Pole wymagane</div>
                {{ validation.last_name }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-row>
          <b-row>
            <b-form-group class="col-md-4" id="street" label="Ulica *" label-for="street">
              <b-form-input v-model="form.step3.street"
                            @change="resetApiValidation('street')"
                            :state="(!$v.form.step3.street.$error && validation.street == null) && null"
                            aria-describedby="street-feedback"></b-form-input>
              <b-form-invalid-feedback id="street-feedback">
                <div class="error" v-if="!$v.form.step3.city.street">Pole wymagane</div>
                {{ validation.street }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group class="col-md-4" id="house_nr" label="Numer domu *" label-for="house_nr">
              <b-form-input v-model="form.step3.house_nr"
                            @input="$v.form.step3.house_nr.$touch()"
                            @change="resetApiValidation('house_nr')"
                            :state="(!$v.form.step3.house_nr.$error && validation.house_nr == null) && null"
                            aria-describedby="house_nr-feedback"></b-form-input>
              <b-form-invalid-feedback id="house_nr-feedback">
                <div class="error" v-if="!$v.form.step3.house_nr.required">Pole wymagane</div>
                {{ validation.house_nr }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group class="col-md-4" id="local_nr" label="Numer lokalu" label-for="local_nr">
              <b-form-input v-model="form.step3.local_nr"></b-form-input>
            </b-form-group>
          </b-row>
          <b-row>

            <b-form-group class="col-md-6" id="city" label="Miejscowość *" label-for="city">
              <b-form-input v-model="form.step3.city"
                            @input="$v.form.step3.city.$touch()"
                            @change="resetApiValidation('city')"
                            :state="(!$v.form.step3.city.$error && validation.city == null) && null"
                            aria-describedby="city-feedback"></b-form-input>
              <b-form-invalid-feedback id="city-feedback">
                <div class="error" v-if="!$v.form.step3.city.required">Pole wymagane</div>
                {{ validation.city }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="col-md-6" id="post_code" label="Kod pocztowy *"
                          label-for="post_code">
              <b-form-input v-model="form.step3.post_code"
                            @input="$v.form.step3.post_code.$touch()"
                            @change="resetApiValidation('city')"
                            :state="(!$v.form.step3.post_code.$error && validation.post_code == null) && null"
                            aria-describedby="postcode-feedback"></b-form-input>
              <b-form-invalid-feedback id="postcode-feedback">
                <div class="error" v-if="!$v.form.step3.post_code.required">Pole wymagane</div>
                <div class="error" v-if="!$v.form.step3.post_code.minLength">Błędna wartość - zbyt
                  krótki kod
                  pocztowy
                </div>
                <div class="error" v-if="!$v.form.step3.post_code.maxLength">Błędna wartość - zbyt
                  długi kod
                  pocztowy
                </div>
                {{ validation.post_code }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-row>

          <b-form-group id="shop" label="Sklep" label-for="shop" v-if="!disabled_shop">
            <b-form-select v-model="form.step3.shop_id"
                           @change="resetApiValidation('shop_id')"
                           :state="validation.shop_id != 0"
                           :options="shop_list"
                           aria-describedby="shop-feedback"></b-form-select>
            <b-form-invalid-feedback id="shop-feedback">
              {{ validation.shop_id }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="email" label="Adres e-mail" label-for="email">
            <b-form-input v-model="form.step3.email"
                          @input="$v.form.step3.email.$touch()"
                          @change="resetApiValidation('email')"
                          :state="(!$v.form.step3.email.$error && validation.email == null) && null"
                          aria-describedby="email-feedback"></b-form-input>
            <b-form-invalid-feedback id="email-feedback">
              <div class="error" v-if="!$v.form.step3.email.email">To nie jest poprawny adres
                e-mail
              </div>
              {{ validation.email }}
            </b-form-invalid-feedback>

          </b-form-group>

          <template v-if="$store.state.settings.app_texts.approvals_prepend_text">
            <div v-html="$store.state.settings.app_texts.approvals_prepend_text"></div>
          </template>

          <br/>
          <template v-if="$store.state.settings.app_texts.approvals_marketing">
            <b-form-checkbox
              switch
              id="user_marketing"
              v-model="form.step3.approvals.marketing"
              v-bind:true-value=true
              v-bind:false-value=false

            >
              <div v-html="$store.state.settings.app_texts.approvals_marketing"></div>
            </b-form-checkbox>
            <br/>
          </template>
          <template v-if="$store.state.settings.app_texts.approvals_marketing_sms">
            <b-form-checkbox
              switch
              id="marketing_sms"
              v-model="form.step3.approvals.marketing_sms"
              v-bind:true-value=true
              v-bind:false-value=false

            >
              <div v-html="$store.state.settings.app_texts.approvals_marketing_sms"></div>
            </b-form-checkbox>
            <br/>
          </template>
          <template v-if="$store.state.settings.app_texts.approvals_marketing_mail">
            <b-form-checkbox
              switch
              id="marketing_mail"
              v-model="form.step3.approvals.marketing_mail"
              v-bind:true-value=true
              v-bind:false-value=false

            >
              <div v-html="$store.state.settings.app_texts.approvals_marketing_mail"></div>
            </b-form-checkbox>
            <br/>
          </template>
          <template v-if="$store.state.settings.app_texts.approvals_marketing_phone">
            <b-form-checkbox
              switch
              id="marketing_phone"
              v-model="form.step3.approvals.marketing_phone"
              v-bind:true-value=true
              v-bind:false-value=false

            >
              <div v-html="$store.state.settings.app_texts.approvals_marketing_phone"></div>
            </b-form-checkbox>
            <br/>
          </template>

          <template v-if="$store.state.settings.app_texts.approvals_profiling">
            <b-form-checkbox
              switch
              id="profiling"
              v-model="form.step3.approvals.profiling"
              v-bind:true-value=true
              v-bind:false-value=false

            >
              <div v-html="$store.state.settings.app_texts.approvals_profiling"></div>
            </b-form-checkbox>
            <br/>
          </template>

          <template v-if="$store.state.settings.app_texts.approvals_append_text">
            <div v-html="$store.state.settings.app_texts.approvals_append_text"></div>
          </template>

          <br/>
          <b-form-group label-for="tos">
            <b-form-checkbox switch
                             v-model="form.step3.tos"
                             @input="$v.form.step3.tos.$touch()"
                             @change="resetApiValidation('tos')"
                             :state="(!$v.form.step3.tos.$error) && null"
                             value="1"
                             unchecked-value="0"
                             aria-describedby="tos-feedback"
            >
              <div v-html="$store.state.settings.app_texts.user_tos" style="display:inline"></div>
            </b-form-checkbox>
            <div class="error" v-if="$v.form.step3.tos.$error"></div>
          </b-form-group>
          <b-form-invalid-feedback id="tos-feedback">
            <div class="error" v-if="!$v.tos.between">Regulamin wymagany</div>
          </b-form-invalid-feedback>
          <rodo></rodo>
          <b-button variant="danger" :disabled="loading" @click="$refs.confirmReset.showModal()">
            Rezygnuję
          </b-button>
          <b-button variant="success" @click="save()" :disabled="loading" class="float-right">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Zarejestruj konto
          </b-button>
        </div>

        <div v-if="step === 4">
          <h1>Informacja </h1>
          Konto zostało poprawnie zarejestrowane. Możesz przejść na
          <b-link :to="`/user-data`">swoje konto</b-link>
        </div>
      </b-form>
    </b-container>
    <confirm-reset ref="confirmReset" :currentStep="step"
                   v-on:resetForm="resetForm()"></confirm-reset>
  </b-container>
</template>
<script>

// komponenty
import {
  required, minLength, maxLength, between, numeric, email, minValue,
} from 'vuelidate/lib/validators';
import ConfirmReset from '@/components/Register/ConfirmReset.vue';
// import ConfirmNextPoints from '@/components/Register/ConfirmNextPoints.vue';
import Rodo from '@/components/Rodo.vue';
// serwisy
import RegisterService from '../_services/register.service';
import NotifyService from '../_services/notify';
import ShopService from '../_services/shop.service';
import UserService from '../_services/user.service';

const registerService = new RegisterService();
const userService = new UserService();
const shopService = new ShopService();
const notify = new NotifyService();
export default {
  props: {},
  name: 'signup',
  components: {
    ConfirmReset, Rodo,
  },
  computed: {
    card_settings() {
      return this.$store.state.settings.card_settings;
    },
  },
  data() {
    return {
      rules: null,
      showConfirmReset: false,
      getSmsSended: null,
      sex: registerService.getSexOptions(),
      form: {
        step1: {
          card_nr: '',
          cell_phone: '',
          have_card: true,
        },
        step2: {
          sms_code: '',
          birth_date: '',
        },
        step3: {
          token: null,
          user_birth_date: '',
          sex: 0,
          first_name: '',
          last_name: '',
          street: '',
          house_nr: '',
          local_nr: '',
          city: '',
          post_code: '',
          shop_id: 0,
          email: '',
          user_marketing: false,
          user_profiling: true,
          user_marketing_ext: null,
          tos: 0,
          approvals: {
            marketing: 0,
            marketing_sms: 0,
            marketing_mail: 0,
            marketing_phone: 0,
            profiling: 0,
          }
        },
      },
      validation: {
        card_nr: null,
        sms_code: null,
        user_birth_date: null,
        shop_id: '',
        sex: null,
        first_name: null,
        last_name: null,
      },
      loading: false,
      checking_sms: false,
      tos: null,
      shop_list: [],
      step: 1,
      disabled_shop: true,
    };
  },
  async created() {
    const $step = localStorage.getItem('step');
    if ($step) {
      this.step = localStorage.getItem('step');
      this.step -= 1;
      this.step += 1;
    }
    if ($step > 1) {
      this.checkShopId();
    }
    this.shop_list = await shopService.getShopList();
    this.shop_list.unshift({value: 0, text: 'Wybierz sklep z listy'});

    // jesli PROFILING ukryty, to domyslnie jako TAK
    if (this.$store.state.settings.app_texts.approvals_profiling === null) {
      this.$set(this.form.step3.approvals, 'profiling', 1);
    }

  },
  watch: {
    step(newStep, oldStep) {
      localStorage.setItem('step', newStep);
      if (newStep === 2) {
        this.getSmsSended = registerService.getSmsSended();
        this.checkShopId();
      }
      if (newStep === 3 && oldStep === 2) {
        this.checkShopId();
      }
      if (newStep === 4) { // 4 step czyli rjestracja dokończona, czyscimy zmiene z localStorage i dajemy tam tylko token
        const token = localStorage.getItem('token');
        registerService.clearSession();
        localStorage.setItem('token', token);
      }
    },
  },
  methods: {
    resetForm() {
      localStorage.clear();
      this.step = 1;
    },
    checkToken() {
      if (!this.form.step2.sms_code.length || this.$v.form.step2.sms_code.$error) {
        return;
      }
      registerService.checkToken(this.form.step2.sms_code)
        .then((response) => {
          const status = response.data.token_is_valid ?? false;
          if (status) {
            this.validation.sms_code = true;
          } else {
            this.validation.sms_code = 'Kod wygląda na niepoprawny';
          }
        });
    },
    checkShopId() {
      this.$store.state.loading = true;
      registerService.getUserData()
        .then((result) => {
          this.disabled_shop = false;
          if (result.shop_id > 0) {
            this.form.step3.shop_id = result.shop_id;
            this.disabled_shop = true;
          }
          this.$store.state.loading = false;
        });
    },
    resetApiValidation(key) {
      this.validation[key] = null;
      return true;
    },
    /**
     * krok pierwszy, sprawdzenie forms przed wyslaniem żądania
     */
    stepOne() {
      // jesli ktos wpisze karte z prefixem 209 mimo ze nie powinien i ma 13 znakow to ucinamy
      this.$set(this.form.step1, 'card_nr', userService.clearCardNumber(this.form.step1.card_nr));
      // sprawdzamy czy nie ma zadnych wewnetrznych bledow przed wyslaniem zadania POST
      this.$v.form.step1.$touch();
      if (this.$v.form.step1.$anyError) {
        return;
      }
      this.stepOneSend();
    },
    prev() {
      this.step -= 1;
    },
    next() {
      this.step += 1;
    },
    /**
     * wysłanie formularza z danymi uczestnika
     *
     *
     */
    save() {
      this.confirmSend();
    },
    /**
     * Wysłanie formularza dla kroku 1
     */
    stepOneSend() {
      this.loading = true;
      registerService.stepOneSend(
        {
          card_nr: this.$store.state.settings.card_settings.prefix + '' + this.form.step1.card_nr,
          cell_phone: this.form.step1.cell_phone,
          have_card: true,
        }
      )
        .then((response) => {
          if (typeof response.data.errors !== 'undefined') {
            return;
          }
          localStorage.setItem('token', response.data.token);
          localStorage.register_step1 = JSON.stringify(this.form.step1);
          this.loading = false;
          this.next();
        })
        .catch((error) => {
          if (typeof error.response.data.errors !== 'undefined') {
            this.validationSet(error.response.data.errors);
          }


        })
        .finally(() => {
          this.loading = false;
        });
    },
    stepTwo() {
      this.$v.form.step2.$touch();
      if (this.$v.form.step2.$anyError) {
        return;
      }
      this.loading = true;
      registerService.secondFormSend(this.form.step2)
        .then((response) => {
          if (response.data.success === true) {
            this.next();
            this.loading = false;
          }
        })
        .catch((error) => { // wysypka czyi blad 4xx
          this.loading = false;
          if (typeof error.response === 'undefined') {
            notify.error('Błąd', 'Spróbuj jeszcze raz');
            return;
          }
          if (error.response.status === 401) {
            this.step = 1;
            userService.clearSession();
            notify.error('Problem z autoryzacja', 'Coś się popsuło, musisz powtórzyć kroki');
            return;
          }
          if (typeof error.response.data.errors !== 'undefined') {
            this.validationSet(error.response.data.errors);
            notify.error('Woops!', 'Znaleźliśmy błędy w formularzu');
          }
        });
    },
    confirmSend() {
      this.$v.form.step3.$touch();
      if (this.$v.form.step3.$anyError) {
        return;
      }
      this.loading = true;
      registerService.confirmSend(this.form.step3)
        .then((response) => {
          if (response.data.success === true) {
            this.next();
            this.loading = false;
          }
        })
        .catch((error) => { // wysypka czyi blad 4xx
          this.loading = false;
          if (typeof error.response === 'undefined') {
            notify.error('Błąd', 'Spróbuj jeszcze raz');
            return;
          }
          if (error.response.status === 401) {
            this.step = 1;
            localStorage.removeItem('token');
            notify.error('Problem z autoryzacja', 'Coś się popsuło, musisz powtórzyć kroki');
            return;
          }
          if (typeof error.response.data.errors !== 'undefined') {
            this.validationSet(error.response.data.errors);
            notify.error('Woops!', 'Znaleźliśmy błędy w formularzu');
          }
        });
    },
    validationSet(errors) {
      Object.keys(errors)
        .forEach((key) => {
          const [message] = errors[key];
          this.validation[key] = message;
        });
    },
  },
  /**
   * walidacje z Vuelidate
   */
  validations: {
    form: {
      step1: {
        card_nr: {
          numeric,
          required,
          minLength: minLength(9),
        },
        cell_phone: {
          numeric,
          required,
          minLength: minLength(9),
        },
      },
      step2: {
        sms_code: {
          required,
          numeric,
        },
        birth_date: {
          required,
        },
      },
      step3: {
        sex: {
          between: between(1, 2),
        },
        first_name: {required},
        last_name: {required},
        city: {required},
        street: {required},
        house_nr: {required},
        post_code: {required, minLength: minLength(6), maxLength: maxLength(6)},
        email: {
          email,
        },
        tos: {
          required,
          minValue: minValue(1),
        },
        shop_id: {required,numeric,minValue: minValue(1)},
      },
    },
    tos: {between: between(1, 2)},
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
</style>
